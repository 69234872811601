/**
 * 全局getter
 */
const getters = {
  name: state => state.user.name,
  isSuper: state => state.user.isSuper,
  permissions: state => state.user.permissions,
  roles: state => state.user.roles,

  // routers: state => state.permission.routers,

  permission_routes: state => state.permission.routers,

  addRouters: state => state.permission.routers,

  device: state => state.app.device,
  sidebar: state => state.app.sidebar,

  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,

};
export default getters;
