

function enumValueToLabel(value, targetEnum) {
  const entry = targetEnum.find(item => item.value === value);
  if (entry) {
    return entry.label;
  }
  // if (process.env.BUILD_ENV === 'development') {
  //   return `没找项（${value}）`;
  // }
  return '-';
}

export default enumValueToLabel;
