import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import router from './router';
import store from './store';
import { getToken } from '@/common/auth';

/**
 * 获取用户信息
 */
NProgress.configure({ showSpinner: false });

/**
 * 免登录白名单
 */
const whiteList = [
  '/login',
];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const { roleId } = store.state.user;
  if (whiteList.includes(to.path) || roleId !== '') {
    // 在免登录白名单或已有登录状态，直接进入
    next();
  } else if (getToken()) {
    const res = await store.dispatch('GetUserInfo');
    const accessRoutes = await store.dispatch('GenerateRoutes', res.data);
    router.addRoutes(accessRoutes);
    next({ ...to, replace: true });
  } else {
    next('/login');

    // store.dispatch('GetUserInfo')
    //   .then(() => {
    //     next({ ...to, replace: true });
    //     return null;
    //   })
    //   .catch(() => {
    // 登录状态设置为true
    //     next('/login');
    //   });
  }
});

router.afterEach(() => {
  NProgress.done();
});
