import Vue from 'vue';
import Vuex from 'vuex';

import app from './app';
import user from './user';
import permission from './permission';
import tagsView from './tagsView';
import enums from './enums';
import settings from './settings';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    enums,
    tagsView,
    settings,
  },
  getters,
  strict: false,
});
