import Http from './http';
import config from '@/config';
import { getToken } from './auth';

const NOT_LOGIN_CODE = 402;

function checkLoginInterceptor(res) {
  const { data } = res;
  if (data && data.code === NOT_LOGIN_CODE) {
    location.href = '/login';
    console.error('您没有登录，请登录后重试');
  }
  return res;
}

function authInterceptor(cfg) {
  const token = getToken();
  if (token) {
    cfg.headers.token = getToken();
  }
  return cfg;
}

const defaultHttp = new Http({
  urlencoded: false,
  baseURL: config.defaultServer,
  timeout: 30000,
});

defaultHttp.axiosInstance.interceptors.request.use(authInterceptor);
defaultHttp.axiosInstance.interceptors.response.use(checkLoginInterceptor);

const defaultRequest = defaultHttp.request.bind(defaultHttp);

export {
  defaultRequest as default,
  defaultHttp,
};
