import mapValues from 'lodash.mapvalues';

// 异步枚举获取配置
const enumsConfig = {
  roles: {
    url: '/admin-api/role/listSelect',
    init: true,
    method: 'GET',

    responseIntercepter: (data) => {
      return data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    },
  },

  products: {
    url: '/admin-api/wym/getProductList',
    init: true,
    method: 'GET',
    // params: {
    //   limit: 100,
    //   page: 1,
    // },

    responseIntercepter: (data) => {
      return data.map((item) => {
        return  {
          label: item.title,
          value: item.product,
        }
      })
      // return data.records.map((item) => {
      //   return {
      //     label: item.name,
      //     value: item.id,
      //     original: item,
      //   }
      // })
    },
  },


};

const asyncEnums = mapValues(enumsConfig, () => []);

export {
  asyncEnums as default,
  enumsConfig,
};
