import toPairs from 'lodash.topairs';
import store from '@/store';
import { enumsConfig } from '@/store/enums/asyncEnums';

const enumsKeys = toPairs(enumsConfig);

enumsKeys.forEach(([key, value]) => {
  if (value.init) {
    store.dispatch('enums/FetchEnum', {
      name: key,
    });
  }
});
