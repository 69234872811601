import request from '@/common/request';

/**
 * 登录
 */
export function login(params) {
  return request({
    url: '/admin-api/login',
    method: 'POST',
    data: params,
  });
}
/**
 * 获取登录用户信息
 * @param {*}
 */
export function getUser() {
  return request({
    url: '/admin-api/loginInfo',
    method: 'GET',
  });
}


/**
 * 获取登录用户信息
 * @param {*}
 */
export function logout() {
  return request({
    url: '/admin-api/logout',
    method: 'GET',
  });
}
