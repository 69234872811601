import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';

Vue.use(VueRouter);


export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true,
  },
  {
    path: '',
    component: Layout,
    redirect: 'dashboard',
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index'),
      name: 'dashboard',
      meta: {
        title: '首页',
        icon: 's-home',
        affix: true,
      },
    }],
  },

  {
    path: '*',
    component: () => import('@/views/errorPage/404'),
    hidden: true,
  },
];

export default new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});

/**
 * 动态路由，根据权限添加
 */
export const asyncRouterMap = [
  {
    path: '/sys',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '系统管理',
      icon: 's-tools',
    },
    children: [
      {
        path: '/releaseManage',
        component: () => import('@/views/releaseManage/index'),
        name: 'releaseManage',
        meta: {
          title: 'APP版本管理',
          icon: 'config',
          permission: ['sys:appOnline'],

        },
      },
      {
        path: '/roleManagement',
        component: () => import('@/views/roleManagement/index'),
        name: 'roleManagement',
        meta: {
          title: '角色管理',
          icon: 'config',
          permission: ['sys:role'],
        },
      },

      {
        path: '/userManage',
        component: () => import('@/views/userManage/index'),
        name: 'userManage',
        meta: {
          title: '用户管理',
          icon: 'config',
          permission: ['sys:user'],
        },
      },



      {
        path: '/adminManage',
        component: () => import('@/views/adminManage/index'),
        name: 'adminManage',
        meta: {
          title: '管理员管理',
          icon: 'config',
          permission: ['sys:admin'],
        },
      },
    ],
  },

  {
    path: '/wymUser',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '用户管理',
      icon: 'cpu',
    },
    children: [
      {
        path: '/wym/userManage',
        component: () => import('@/views/wym/userManage/index'),
        name: 'userManage',
        meta: {
          title: '用户管理',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },
      {
        path: '/wym/userFinancing',
        component: () => import('@/views/wym/userFinancing/index'),
        name: 'userFinancing',
        meta: {
          title: '财务日志',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },

      {
        path: '/wym/userWithdraw',
        component: () => import('@/views/wym/userWithdraw/index'),
        name: 'userWithdraw',
        meta: {
          title: '提现列表',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },

    ],
  },

  {
    path: '/wymMiner',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '矿机管理',
      icon: 'cpu',
    },
    children: [
      // {
      //   path: '/wymMiner/todayRelease',
      //   component: () => import('@/views/wymMiner/todayRelease/index'),
      //   name: 'todayRelease',
      //   meta: {
      //     title: '释放今日收益',
      //     icon: 'config',
      //     // permission: ['sys:user'],
      //   },
      // },
      {
        path: '/wymMiner/paramsSetting',
        component: () => import('@/views/wymMiner/paramsSetting/index'),
        name: 'paramsSetting',
        meta: {
          title: '参数设置',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },

      {
        path: '/wymMiner/shopMiner',
        component: () => import('@/views/wymMiner/shopMiner/index'),
        name: 'shopMiner',
        meta: {
          title: '矿机商店',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },

      {
        path: '/wymMiner/userMiner',
        component: () => import('@/views/wymMiner/userMiner/index'),
        name: 'userMiner',
        meta: {
          title: '用户矿机',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },

      {
        path: '/wymMiner/productList',
        component: () => import('@/views/wymMiner/productList/index'),
        name: 'productList',
        meta: {
          title: '机柜管理',
          icon: 'config',
          // permission: ['sys:user'],
        },
      },
    ],
  },


  // {
  //   path: '/invest',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: '云算力投资',
  //     icon: 'cpu',
  //   },
  //   children: [
  //     {
  //       path: '/serverManage',
  //       component: () => import('@/views/serverManage/index'),
  //       name: 'serverManage',
  //       meta: {
  //         title: '柜机管理',
  //         permission: ['power:server'],
  //       },
  //     },
  //     {
  //       path: '/storeInvestManage',
  //       component: () => import('@/views/storeInvestManage/index'),
  //       name: 'storeInvestManage',
  //       meta: {
  //         title: '存储投资管理',
  //         permission: ['power:invest:list'],
  //       },
  //     },
  //     {
  //       path: '/minerOutput',
  //       component: () => import('@/views/minerOutput/index'),
  //       name: 'minerOutput',
  //       meta: {
  //         title: '矿工产出',
  //         permission: ['power:miner:outputRecordList'],
  //       },
  //     },

  //     {
  //       path: '/serverOutputConfig',
  //       component: () => import('@/views/serverOutputConfig/index'),
  //       name: 'serverOutputConfig',
  //       meta: {
  //         title: '柜机产出配置',
  //         permission: ['power:serverConfig'],
  //       },
  //     },

  //     {
  //       path: '/withdrawManage',
  //       component: () => import('@/views/withdrawManage/index'),
  //       name: 'withdrawManage',
  //       meta: {
  //         title: '提现管理',
  //         permission: ['power:serverConfig'],
  //       },
  //     },

  //   ],
  // },

  // {
  //   path: '/miner',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: '矿工管理',
  //     icon: 'coin',
  //   },
  //   children: [

  //     // {
  //     //   path: '/storeInvestManage',
  //     //   component: () => import('@/views/storeInvestManage/index'),
  //     //   name: 'storeInvestManage',
  //     //   meta: {
  //     //     title: '存储投资管理',
  //     //     // permission: ['admin/list'],
  //     //   },
  //     // }
  //   ],
  // }

];
