import '@babel/polyfill';
import Vue from 'vue';
import 'normalize.css';
import Element from 'element-ui';
import Quill from 'quill';

import './styles/element-variables.scss';
// import Cookies from 'js-cookie';
import router from '@/router';
import App from '@/views';
import store from '@/store';
import filters from '@/filters';
import config from '@/config';

import '@/permission';
import '@/styles/index.scss';

window.Quill = Quill;

Vue.config.productionTip = false;
Vue.prototype.$config = config;

Vue.use(Element, {
  size: 'small', // set element-ui default size
  // size: Cookies.get('size') || 'medium', // set element-ui default size
});

/**
 * 注册filters
 */
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

