/* eslint-disable no-param-reassign */
import { login, getUser } from '@/api/login';
import { removeToken, setToken } from '@/common/auth';

const user = {
  // namespaced: true,
  state: {
    name: '',
    uid: '',
    account: '',
    roleId: '',
    // roles: [],
    isSuper: 0,
    permissions: '',
  },

  mutations: {
    SET_ACCOUNT: (state, account) => {
      state.account = account;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_UID: (state, uid) => {
      state.uid = uid;
    },
    SET_USER_ROLE: (state, roleId) => {
      state.roleId = roleId;
    },

    SET_IS_SUPER: (state, isSuper) => {
      state.isSuper = isSuper;
    },

    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
  },

  actions: {
    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUser(state.token)
          .then((response) => {
            const { success, message, data } = response;
            // console.log(data)
            if (!success) {
              reject(message);
            } else {
              commit('SET_ACCOUNT', data.account);
              commit('SET_NAME', data.account);
              commit('SET_UID', data.id);
              commit('SET_USER_ROLE', data.role_id);
              commit('SET_IS_SUPER', data.is_super);
              commit('SET_PERMISSIONS', data.resources);
              resolve(response);
            }
            return null;
          })
          .catch((error) => {
            reject(error);
            return null;
          });
      });
    },

    // 登出
    LogOut({ commit }) {
      removeToken();
      commit('SET_NAME', '');
      commit('SET_PERMISSIONS', []);
      commit('SET_USER_ROLE', '');
      commit('SET_IS_SUPER', '');
      window.location.href = '/logout';
    },
    LoginByUsername(context, payload) {
      return new Promise((resolve, reject) => {
        login({
          account: payload.username,
          password: payload.password,
        })
          .then((response) => {
            const { success, message, data } = response;
            if (!success) {
              reject(message);
            } else {
              setToken(data.t);
              resolve(response);
            }
            return null;
          })
          .catch((error) => {
            reject(error);
            return null;
          });
      });
    },
  },
};

export default user;
