
const staticEnums = {
  // 用户状态
  userStatus: [
    {
      value: 0,
      label: '禁用',
    },
    {
      value: 1,
      label: '正常',
    },
  ],
   // 上线
   onlineStatus: [
    {
      value: 0,
      label: '未上线',
    },
    {
      value: 1,
      label: '已上线',
    },
  ],

  userStatus2: [
    {
      value: 1,
      label: '正常',
    },
    {
      value: 2,
      label: '冻结',
    },
  ],

  moneyType: [
    {
      value: 4,
      label: '存储收益产出',
    }, {
      value: 8,
      label: '存储收益释放',
    }, {
      value: 10,
      label: 'FIL提现',
    }, {
      value: 11,
      label: 'FIL提现失败增加',
    }, {
      value: 12,
      label: 'FIL充值',
    },
    {
      value: 17,
      label: 'fil提现失败增加',
    }
  ],

  // 审核状态 apply audited reject
  withdrawState: [
    {
      value: 0,
      label: '待审核',
    },
    {
      value: 1,
      label: '已通过',
    },
    {
      value: 2,
      label: '已拒绝',
    },
  ],

  minerSealState: [

    {
      value: true,
      label: '封装完成',
    },
    {
      value: false,
      label: '已结束',
    },
  ],

  shifangState: [

    {
      value: 0,
      label: '未释放',
    },
    {
      value: 1,
      label: '已释放',
    },
  ],




  // 审核状态 apply audited reject
  auditState: [
    {
      value: 'apply',
      label: '待审核',
    },
    {
      value: 'audited',
      label: '已通过',
    },
    {
      value: 'reject',
      label: '已拒绝',
    },
  ],
  // 支付状态
  payState: [
    {
      value: 0,
      label: '否',
    },
    {
      value: 1,
      label: '是',
    },
  ],

  // 支付状态
  dealState: [
    {
      value: 0,
      label: '未处理',
    },
    {
      value: 1,
      label: '已处理',
    },
  ],

  // yes or no
  yesOrNo: [
    {
      value: 0,
      label: '否',
    },
    {
      value: 1,
      label: '是',
    },
  ],

  // 启用禁用
  onOrOff: [
    {
      value: 0,
      label: '禁用',
    },
    {
      value: 1,
      label: '正常',
    },
  ],

  buyOrSell: [
    {
      value: 0,
      label: '买',
    },
    {
      value: 1,
      label: '卖',
    },
  ],
  // 0未封装 1封装中 2封装完成|存储中 3已报废
  serverStatus: [
    {
      value: 0,
      label: '未封装',
    },
    {
      value: 1,
      label: '封装中',
    },
    {
      value: 2,
      label: '封装完成',
    },
    {
      value: 3,
      label: '已报废',
    },
  ],
  // 矿机设置
  // 0 待设置,封装中状态下需要设置产出 1已出矿 2已发收益
  serverOutputStatus: [
    {
      value: 0,
      label: '待设置',
    },
    {
      value: 1,
      label: '已出矿',
    },
    {
      value: 2,
      label: '已发收益',
    },
  ],

  investStatus: [
    {
      value: 0,
      label: '准备中',
    },
    {
      value: 1,
      label: '投资中',
    },
    {
      value: 2,
      label: '到期',
    },
    {
      value: 3,
      label: '暂停收益',
    },
  ]
};

export default staticEnums;
