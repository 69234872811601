import { asyncRouterMap, constantRouterMap } from '@/router';

/**
 * 判断是否与当前用户权限匹配
 */
function hasPermission(resources, route) {
  if (route.meta && route.meta.permission && route.meta.permission.length) {
    return route.meta.permission.some((item) => {
      return !!resources.find(resource => resource.resource === item);
    });
  }
  return true;
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param resources
 */
// eslint-disable-next-line
function filterAsyncRouter(asyncRouterMap, resources) {
  const accessedRouters = asyncRouterMap.filter((route) => {
    if (hasPermission(resources, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, resources);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}

const permission = {
  state: {
    addRouters: [],
    routers: constantRouterMap,
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
  },
  actions: {
    /**
     * 根据权限生成路由
     */
    GenerateRoutes({ commit }, data) {
      return new Promise((resolve) => {
        // const accessedRouters = filterAsyncRouter(asyncRouterMap, []);
        const accessedRouters = filterAsyncRouter(asyncRouterMap, data.resources);
        commit('SET_ROUTERS', accessedRouters);
        resolve(accessedRouters);
      });
    },
  },
};

export default permission;
